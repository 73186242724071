.list-group a:hover {
    background-color: #254283;
    color: white;
    cursor: pointer;
}

.sidebar-scroll {
    max-height: 80vh;
    overflow-y: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 8px;
        height: 0;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-button {
        display: none;
    }
}