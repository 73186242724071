

.form-login {
    position: relative;
    top: 15vh;    
}

.button-main {
    color: white;
    background-color: #254283 !important;
    border: none
}

.button-main:hover {
    color: white;
    background-color: #001d5d !important;
    border: none
}

.focus-main {
    color: white;
    background-color: #254283 !important;
    border: none
}

#image-background {
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
}




